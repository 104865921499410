
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import { authCssFix } from '@/helpers/html'

import FormError from '@/components/ui/FormError.vue'
import { backend } from '@/services/backend'
import { ValidationState } from '@/helpers/form'
import { AccountStatus } from '@/models/interfaces'
import { Sentry } from '@/services/sentry'
import AccountStatusInfo from '@/app/components/account/AccountStatusInfo.vue'
import { wait } from '@/helpers/vue-wait'
import { notificationService } from '@/services/notification'
import { localLogout } from '@/services/auth'

@Options({
  components: {
    FormError,
    AccountStatusInfo,
  },
})
export default class AccountDeletionConfirmation extends Vue {
  private validation: ValidationState = new ValidationState()
  private accountStatus: AccountStatus | null = null
  private deletionConfirmationComplete: boolean = false
  private hasError: boolean = false

  async beforeMount(): Promise<void> {
    try {
      const token = this.$route.query.delete_token
      if (token) {
        this.accountStatus = await backend.getAccountStatusByDeleteToken(
          token as string,
        )
      } else {
        this.accountStatus = await backend.getAccountStatus()
      }
    } catch (error) {
      // The error here is usually NetworkError or Timeout, but
      // we still capture all errors to Sentry to be aware if
      // error rate increases.
      Sentry.withScope(function (scope) {
        scope.setFingerprint(['account-status-loading-error'])
        Sentry.captureException(error)
      })
      this.hasError = true
      this.validation.showErrors(error)
    }
  }
  mounted(): void {
    authCssFix()
  }

  async confirmDeletion(): Promise<void> {
    this.validation.reset()

    try {
      wait.start(this, 'Deleting')
      await notificationService.unsubscribe()

      const token = this.$route.query.delete_token
      await backend.confirmAccountDeletion(token as string)
      this.deletionConfirmationComplete = true

      try {
        await localLogout()
      } catch (err) {
        // Ignore any errors raised here.
      }

      wait.end(this, 'Deleting')
    } catch (error) {
      wait.end(this, 'Deleting')
      this.hasError = true
      Sentry.captureException(error)
      this.validation.showErrors(error)
    }
  }

  get title(): string {
    return this.deletionConfirmationComplete
      ? 'Account Deleted'
      : 'Confirm Account Deletion'
  }
}
