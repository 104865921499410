import { request } from './request'
import { ab } from './ab'
import { auth, localLogout } from './auth'
import { CheckResponseHint } from './ab'
import { storage } from '../helpers/storage'
import { AppStorePluginProduct } from '@/services/cordova.purchase.types'
import { Mixpanel } from '@/analytics/mixpanel'
import { Sentry } from '@/services/sentry'
import { readwise } from '@/services/readwise'

import {
  NewUser,
  NewUserGoogle,
  NewUserApple,
  NewUserFacebook,
  NewUserData,
  User,
  AuthData,
  ResetData,
  AuthUser,
} from '@/models/interfaces.auth'

import {
  DocListItem,
  Doc,
  Book,
  DocExportStats,
  Article,
  ApiResultDocs,
  ApiResultBooks,
  ApiResultArticles,
  ApiResultTags,
  PublicBook,
  PublicBookPdf,
  Exercise,
  Forum,
  Thread,
  Comment,
  Billing,
  Plan,
  Annotation,
  AnnotationContent,
  AnnotationExportCredentials,
  AnnotationWithReadwiseToken,
  HighlightsAllDoc,
  AnnotationWithContent,
  Questionnaire,
  ReviewInfo,
  ReadwiseToken,
  Recommendation,
  NotificationSettings,
  NotionCredentials,
  AccountStatus,
  CollectionListItem,
  ApiResultCollectionWithBooks,
  BookOfTheDayItem,
  ApiResult,
  ExcerptData,
  PublicPodcastEpisode,
  DocGroupListItem,
  DocGroupItem,
  EpisodeListItem,
  DocWithContent,
  TagListItem,
  BookListItem,
  CustomList,
  CustomListListItem,
  ApiResultListWithDocs,
} from '@/models/interfaces'
import { QuestionnaireUserChoices } from '@/models/long.onboarding.types'
import { AnnotationView, ContentView } from '@/models/doc'
import { weglotTranslateToLanguageFrom } from './weglot'

function checkUsername(data: any): void {
  if (!auth.getUsername() && data.author.username) {
    auth.setUsername(data.author.username)
  }
}

export const backend = {
  system: false,

  async getBooks(signal?: AbortSignal): Promise<ApiResultBooks> {
    const response = await request.get(`books/`, { signal: signal })
    return response.data
  },

  async getCollectionBooks(
    collectionUrlSlug: string,
  ): Promise<ApiResultCollectionWithBooks> {
    const response = await request.get(`collections/${collectionUrlSlug}`)
    const data = response.data.data
    const docsData = data.docs.data
    delete data.docs
    const collection = data

    return {
      data: docsData,
      tags: docsData.tags,
      collection: collection,
    }
  },

  async getBooksWithRecommendations(): Promise<ApiResultBooks> {
    const [booksResponse, recommendations] = await Promise.all([
      this.getBooks(),
      this.getRecommendations(),
    ])

    const books = booksResponse.data

    return {
      data: this.addRecommendations(books, recommendations),
      tags: booksResponse.tags,
    }
  },

  async getLatestPopularBooks(): Promise<Book[]> {
    const response = await request.get(`books/latest_popular`)
    return response.data.data
  },

  async getBook(url_slug: string): Promise<Book> {
    const response = await request.get(
      `books/${url_slug}`,
      // Headers, if needed.
      this.headers(),
    )
    return response.data.data
  },

  async getBookCollections(): Promise<CollectionListItem[]> {
    const response = await request.get(`collections/`)
    return response.data.data
  },

  async getBookOfTheDay(): Promise<ApiResult<BookOfTheDayItem | undefined>> {
    const response = await request.get(`books/book-of-the-day`)
    return response.data
  },

  async getBookPdf(id: string): Promise<Blob> {
    const response = await request.get(`pdfbooks/${id}`, {
      responseType: 'blob',
    })
    return response.data
  },

  async getDocExportStats(id: string): Promise<DocExportStats> {
    const response = await request.get(`doc_export_stats/${id}`)
    return response.data.data
  },

  async getBookPdfContent(url_slug: string): Promise<Book> {
    const response = await request.get(
      `books_pdf_content/${url_slug}`,
      // Headers, if needed.
      this.headers(),
    )
    return response.data.data
  },

  async getArticles(signal?: AbortSignal): Promise<ApiResultArticles> {
    const response = await request.get(`articles/`, { signal })
    return response.data
  },

  async getTags(): Promise<ApiResultTags> {
    const response = await request.get(`books/tags?min_docs=3`)
    return response.data
  },

  async getBookTags(): Promise<string[]> {
    const response = await request.get(`books/tags`)
    return response.data.data.map((item: TagListItem) => item.name)
  },

  async getQuestionnaireTags(): Promise<ApiResultTags> {
    const response = await request.get(`questionnaire/tags`)
    return response.data
  },

  async getBooksByTags(tagsData: string[]): Promise<ApiResultBooks> {
    const response = await request.post(`questionnaire/popular_books_by_tags`, {
      tags: tagsData,
    })
    return response.data
  },

  async saveQuestionnaire(data: Questionnaire): Promise<void> {
    await request.post(`questionnaire/save`, {
      ...data,
      tags: data.tags.map((e) => e.id),
    })
  },

  async saveLongQuestionnaire(data: QuestionnaireUserChoices): Promise<void> {
    await request.post(`questionnaire/long/save`, {
      choices: {
        ...data,
      },
    })
  },

  async getArticle(url_slug: string): Promise<Article> {
    const response = await request.get(`articles/${url_slug}`)
    return response.data.data
  },

  async getPublicBook(public_url_slug: string): Promise<PublicBook> {
    const response = await request.get(`pages/books/${public_url_slug}`)
    return response.data.data
  },

  async getPublicBookPdf(pdf_url_slug: string): Promise<PublicBookPdf> {
    const response = await request.get(`pages/pdf/${pdf_url_slug}`)
    return response.data.data
  },

  async getPodcasts(signal?: AbortSignal): Promise<DocGroupListItem[]> {
    const response = await request.get(`podcasts/`, { signal: signal })
    return response.data.data
  },

  async getPublicPodcasts(): Promise<DocGroupListItem[]> {
    const response = await request.post(`pages/podcasts/`)
    return response.data.data
  },

  async getPublicPodcast(url_slug: string): Promise<DocGroupItem> {
    const response = await request.post(`pages/podcasts/${url_slug}`)
    return response.data.data
  },

  async getFollowedPodcastEpisodes(): Promise<EpisodeListItem[]> {
    const response = await request.get(`podcasts/episodes/followed`)
    return response.data.data
  },

  async getPodcast(url_slug: string): Promise<DocGroupItem> {
    const response = await request.get(`podcasts/${url_slug}`)
    return response.data.data
  },

  async getDocSearch(
    doc_type: string,
    term: string = '',
    limit: number = 20,
    offset: number = 0,
    signal?: AbortSignal,
  ): Promise<ApiResultDocs> {
    term = await weglotTranslateToLanguageFrom(term)
    const response = await request.get('search', {
      params: {
        term,
        doc_type,
        limit,
        offset,
      },
      signal: signal,
    })
    return response.data
  },

  async getPodcastSearch(
    term: string = '',
    limit: number = 20,
    offset: number = 0,
  ): Promise<DocGroupListItem[]> {
    term = await weglotTranslateToLanguageFrom(term)
    const response = await request.get('podcasts/search', {
      params: {
        term,
        limit,
        offset,
      },
    })
    return response.data.data
  },

  async getPublicPodcastEpisode(
    episode_url_slug: string,
  ): Promise<PublicPodcastEpisode> {
    const response = await request.get(
      `pages/podcast_episodes/${episode_url_slug}`,
    )
    return response.data.data
  },

  async getPodcastEpisode(episode_url_slug: string): Promise<DocWithContent> {
    const response = await request.get(`podcasts/episodes/${episode_url_slug}`)
    return response.data.data
  },

  async follow(podcastID: string): Promise<DocGroupItem> {
    const response = await request.post(`doc_group/follow/${podcastID}`)
    return response.data.data
  },

  async unfollow(podcastID: string): Promise<DocGroupItem> {
    const response = await request.post(`doc_group/unfollow/${podcastID}`)
    return response.data.data
  },

  async getPublicHighlight(
    highlight_id: string,
  ): Promise<AnnotationWithContent> {
    const response = await request.get(`highlights/public/${highlight_id}`)
    return response.data.data
  },
  async getHighlight(highlight_id: string): Promise<AnnotationWithContent> {
    const response = await request.get(`highlights/${highlight_id}`)
    return response.data.data
  },

  async saveExercise(
    content: ContentView,
    exercise: Exercise,
  ): Promise<Exercise> {
    const response = await request.post(`workouts/`, exercise)
    const savedExercise = response.data.data
    try {
      Mixpanel.trackExerciseSave(content, savedExercise)
    } catch (error) {
      Sentry.captureException(error)
    }
    return savedExercise
  },

  async discussExercise(
    content: ContentView,
    exercise: Exercise,
  ): Promise<Thread> {
    const response = await request.get(`workouts/${exercise.id}/discuss`)
    const thread = response.data.data
    try {
      Mixpanel.trackExerciseDiscuss(content, exercise)
    } catch (error) {
      Sentry.captureException(error)
    }
    return thread
  },

  async getForum(id: string): Promise<Forum> {
    const response = await request.get(`forums/${id}`)
    return response.data.data
  },

  async updateForumNotifyNewThread(
    forumId: string,
    active: boolean,
  ): Promise<Forum> {
    const response = await request.post(`forums/${forumId}/notify_new_thread`, {
      active: active,
    })
    return response.data.data
  },

  async getThread(url_slug: string): Promise<Thread> {
    const response = await request.get(`threads/${url_slug}`)
    return response.data.data
  },

  async updateThreadNotifyNewComment(
    url_slug: string,
    active: boolean,
  ): Promise<Thread> {
    const response = await request.post(
      `threads/${url_slug}/notify_new_comment`,
      {
        active: active,
      },
    )
    return response.data.data
  },

  async saveThread(book: Book, thread: Thread): Promise<Thread> {
    let response
    if (!thread.id) {
      response = await request.post(`forums/${book.forum_id}`, {
        title: thread.title,
        text: thread.text,
        user_workout_id: thread.user_workout_id,
        username: thread.username,
      })
      try {
        Mixpanel.trackThreadCreate(book, response.data.data)
      } catch (error) {
        Sentry.captureException(error)
      }
    } else {
      response = await request.post(`threads/${thread.url_slug}`, {
        title: thread.title,
        text: thread.text,
        username: thread.username,
      })
      try {
        Mixpanel.trackThreadUpdate(book, response.data.data)
      } catch (error) {
        Sentry.captureException(error)
      }
    }
    const data = response.data.data
    checkUsername(data)
    return data
  },

  async saveComment(
    doc: Doc,
    thread: Thread,
    comment: Comment,
  ): Promise<Comment> {
    const response = await request.post(`threads/${thread.url_slug}/comments`, {
      text: comment.text,
      username: comment.username,
    })
    const data = response.data.data
    try {
      Mixpanel.trackCommentCreate(doc, thread, data)
    } catch (error) {
      Sentry.captureException(error)
    }
    checkUsername(data)
    return data
  },

  async saveReply(
    doc: Doc,
    thread: Thread,
    comment: Comment,
    reply: Comment,
  ): Promise<Comment> {
    const response = await request.post(`comments/${comment.id}/comments`, {
      text: reply.text,
      username: reply.username,
    })
    const data = response.data.data
    try {
      Mixpanel.trackReplyCreate(doc, thread, comment, data)
    } catch (error) {
      Sentry.captureException(error)
    }
    checkUsername(data)
    return data
  },

  async getBilling(): Promise<Billing> {
    const response = await request.get(`billing/`)
    return response.data.data
  },

  async subscribe(new_plan: Plan): Promise<Billing> {
    const response = await request.post(`billing/subscribe`, new_plan)
    const newBilling = response.data.data
    return newBilling
  },

  async cancelTrial(): Promise<Billing> {
    const response = await request.post(`billing/trial/cancel`)
    const billing = response.data.data
    try {
      Mixpanel.trackCancelTrial(billing)
    } catch (error) {
      Sentry.captureException(error)
    }
    return billing
  },

  /**
   * Save card on backend.
   *
   * The `token` is the card data returned by Stripe.
   */
  async saveCard(token: any): Promise<Billing> {
    const response = await request.post(`billing/card`, token)
    const billing = response.data.data
    Mixpanel.trackCardSave(billing)
    return billing
  },

  async savePaymentMethod(token: any): Promise<Billing> {
    const response = await request.post(`billing/setup-intent`, token)
    const billing = response.data.data
    Mixpanel.trackCardSave(billing)
    return billing
  },

  async getSetupIntent(): Promise<any> {
    const response = await request.get('billing/setup-intent')
    return response.data
  },

  async cancelSetupIntent(setupIntent: any): Promise<any> {
    const response = await request.post('billing/setup-intent/cancel', {
      id: setupIntent.id,
    })
    return response.data
  },

  async createHighlight(
    content: AnnotationContent,
    model: Annotation,
  ): Promise<AnnotationExportCredentials> {
    const response = await request.post(`/highlights/${content.id}`, model)
    const annotation: AnnotationExportCredentials = response.data.data
    try {
      Mixpanel.trackHighlightCreate(content, annotation)
    } catch (error) {
      Sentry.captureException(error)
    }
    readwise.sendOne(annotation.readwise_token, {
      ...annotation,
      content: content,
    })
    this.sendToNotion(
      annotation.notion_database_id,
      annotation.notion_integration_token,
      content,
      model,
    ).catch((error) => {
      const notionError = error.response.data.errors._schema[0]
      if (notionError === 'Error exporting to Notion') {
        Sentry.captureException(notionError)
      } else {
        Sentry.withScope(function (scope) {
          scope.setFingerprint(['expected-notion-error'])
          Sentry.captureException(notionError)
        })
      }
    })
    return annotation
  },

  async updateHighlight(
    content: AnnotationContent,
    model: Annotation,
  ): Promise<AnnotationWithReadwiseToken> {
    const response = await request.post(
      `/highlights/${content.id}/${model.id}`,
      model,
    )
    const annotation: AnnotationWithReadwiseToken = response.data.data
    try {
      Mixpanel.trackHighlightUpdate(content, annotation)
    } catch (error) {
      Sentry.captureException(error)
    }

    readwise.sendOne(annotation.readwise_token, {
      ...annotation,
      content: content,
    })
    return annotation
  },

  async updateHighlightNote(
    model: AnnotationView,
  ): Promise<AnnotationWithReadwiseToken> {
    const response = await request.patch(
      `/highlights/${model.contentId}/${model.id}`,
      { text: model.text },
    )
    const annotation: AnnotationWithReadwiseToken = response.data.data

    readwise.sendOne(annotation.readwise_token, {
      ...annotation,
      content: model.content,
    })
    return annotation
  },

  async deleteHighlight(
    content: AnnotationContent,
    highlight: Annotation,
  ): Promise<Annotation> {
    const response = await request.delete(
      `/highlights/${content.id}/${highlight.id}`,
    )
    const annotation: AnnotationWithReadwiseToken = response.data.data
    try {
      Mixpanel.trackHighlightDelete(content, annotation)
    } catch (error) {
      Sentry.captureException(error)
    }
    return annotation
  },

  async shareHighlight(
    content: ContentView,
    model: Annotation,
  ): Promise<Annotation> {
    const response = await request.post(
      `/highlights/share/${content.id}/${model.id}`,
      model,
    )
    const annotation = response.data.data
    try {
      Mixpanel.trackHighlightShare(content, annotation)
    } catch (error) {
      Sentry.captureException(error)
    }
    return annotation
  },

  async getHighlights(): Promise<AnnotationWithContent[]> {
    const response = await request.get(`/highlights/`)
    return response.data.data
  },

  async getHighlightsGrouped(): Promise<HighlightsAllDoc[]> {
    const response = await request.get('/highlights/grouped')
    return response.data.data
  },

  async saveProgress(content: ContentView): Promise<Book> {
    const response = await request.post(`/home/progress/${content.id}`)
    return response.data.data
  },

  async saveReadingPosition(
    content: ContentView,
    position_percent: number,
  ): Promise<Book> {
    const response = await request.post('/home/position', {
      position_chapter: content.id,
      position_percent: position_percent,
    })

    return response.data.data
  },

  async saveAudioPosition(doc: Doc, position: number): Promise<void> {
    await request.post(`/home/audio_progress`, {
      doc_id: doc.id,
      audio_position: position,
    })
  },

  async getHomeArticles(): Promise<ApiResultArticles> {
    const response = await request.get(`/home/articles/`)
    return response.data
  },

  async getNewReleases(): Promise<ApiResultBooks> {
    const response = await request.get(`/home/new_releases`)
    return response.data
  },

  async moveBookToList(doc: DocListItem, list: string): Promise<Book> {
    const response = await request.post(`/home/move/${doc.id}/${list}`)
    Mixpanel.trackMoveBookToList(doc, list)
    return response.data.data
  },

  async favorite(doc: DocListItem): Promise<Book> {
    const response = await request.post(`/home/favorite/${doc.id}`)
    const data = response.data.data
    Mixpanel.trackFavorite(doc, true)
    return data
  },

  async unfavorite(doc: DocListItem): Promise<Book> {
    const response = await request.post(`/home/unfavorite/${doc.id}`)
    Mixpanel.trackFavorite(doc, false)
    return response.data.data
  },

  async getReadwiseToken(): Promise<ReadwiseToken> {
    const response = await request.get(`/export/readwise/token`)
    return response.data.data
  },

  async saveReadwiseToken(token: ReadwiseToken): Promise<void> {
    await request.post(`export/readwise/on`, token)
  },

  async removeReadwiseToken(): Promise<void> {
    await request.post(`export/readwise/off`)
  },

  async getNotionCredentials(): Promise<NotionCredentials> {
    const response = await request.get(`/export/notion/credentials`)
    return response.data.data
  },

  async saveNotionCredentials(credentials: NotionCredentials): Promise<void> {
    await request.post(`export/notion/on`, credentials, { timeout: 0 })
  },

  async removeNotionCredentials(): Promise<void> {
    await request.post('export/notion/off')
  },

  async sendToNotion(
    notion_database_id: string | null,
    notion_integration_token: string | null,
    content: AnnotationContent,
    model: Annotation,
  ): Promise<void> {
    if (notion_database_id === null || notion_integration_token === null) {
      return
    }
    await request.post(`export/notion/highlights/${content.id}`, model)
  },

  async sendToKindle(urlSlug: string, kindleEmail: string): Promise<void> {
    await request.get(`kindle_export/${urlSlug}?kindle_email=${kindleEmail}`)
  },

  async getReviewInfo(
    platform: string,
    appVersion: string,
  ): Promise<ReviewInfo> {
    const response = await request.get(`/home/review`, {
      params: {
        platform: platform,
        app_version: appVersion,
      },
    })
    return response.data
  },

  async saveReviewInfo(platform: string, appVersion: string): Promise<void> {
    await request.post(`/home/review`, {
      platform: platform,
      app_version: appVersion,
    })
  },

  /*
   * Prepare new user data.
   *
   * This a unified method used both by regular and social registration.
   *
   * Because of the difference in the incoming data:
   * - for regular login: email and password pair
   * - for social login: social (Google/Facebook) authentication token.
   *
   * We do the following:
   * - Initialiaze the A/B experiment data
   * - Start the first page tracking
   * - Track user cookies
   * - Merge this data with incoming new user data and return it as a result.
   */
  async prepareNewUserData(): Promise<NewUserData> {
    // Add A/B event data and current exepriments to the
    // /signup request, so we can start server-side
    // experiments on signup.
    const abData = await ab.eventData(true)
    const firstPage = storage.getItem('sf_first_page') || ''
    const marketingUrl = storage.getItem('sf_marketing_url') || ''
    const data = {
      ab: abData,
      first_url: firstPage,
      marketing_url: marketingUrl,
      cookies: document.cookie ? document.cookie : '',
    }
    return data
  },

  /*
   * Handle Facebook login or registration.
   */
  async facebookLoginOrRegister(newUser: NewUserFacebook): Promise<AuthData> {
    const userData = await this.prepareNewUserData()
    const authData = { ...newUser, ...userData }
    const response = await request.post(`auth/facebook`, authData, {
      // We need to send cookies with this request.
      withCredentials: true,
    })
    this.checkAbResponse(response)
    return response.data.data
  },

  async getUserFullname(): Promise<string> {
    const response = await request.get(`auth/name`)
    return response.data.full_name
  },

  /*
   * Handle Apple login or registration.
   */
  async appleLoginOrRegister(newUser: NewUserApple): Promise<AuthData> {
    const userData = await this.prepareNewUserData()
    const authData = { ...newUser, ...userData }
    const response = await request.post(`auth/apple`, authData, {
      // We need to send cookies with this request.
      withCredentials: true,
    })
    this.checkAbResponse(response)
    return response.data.data
  },

  /*
   * Handle Google login or registration.
   */
  async googleLoginOrRegister(
    newUser: NewUserGoogle,
    isIOS: boolean = false,
  ): Promise<AuthData> {
    const userData = await this.prepareNewUserData()
    const authData = { ...newUser, ...userData }
    // We have special processing for IOS requests on the server
    // (need to use the iOS app client token).
    const path = isIOS ? 'auth/google/ios' : 'auth/google'
    const response = await request.post(path, authData, {
      // We need to send cookies with this request.
      withCredentials: true,
    })
    this.checkAbResponse(response)
    return response.data.data
  },

  /*
   * Handle regular registration.
   */
  async register(newUser: NewUser): Promise<AuthData> {
    const userData = await this.prepareNewUserData()
    const authData = { ...newUser, ...userData, full_name: undefined }
    const response = await request.post(`auth/register`, authData, {
      // We need to send cookies with this request.
      withCredentials: true,
    })
    this.checkAbResponse(response, CheckResponseHint.register)
    return response.data.data
  },

  /*
   * Add system headers to the request.
   *
   * Note: for now we only use the system user in PreviewPdfPage.vue,
   * which authenticates and sends GET /book/xxx reqeust,
   * so we only add system header in these two methods.
   *
   * In general, it would be good to exctract wrappers for request.get
   * and request.post and use them in all methods.
   * Then we can add system headers inside these wrappers, so they will
   * be applied to all API methods.
   *
   * Note: we don't save the `system` flag into the user auth storage,
   * it is enough to keep it on the `backend` object, it would be lost
   * after page reload, but it is fine since the build process does not
   * reload pages.
   */
  headers(): Record<string, unknown> {
    if (this.system === true) {
      // The `system` parameter indicates the system user we use during the build
      // process.
      // When set to true, it will add a special X-SF-SYSTEM-USER header to notify
      // the backend about it (we use it to set lower performance trcking sample rate).
      return {
        headers: {
          'X-SF-SYSTEM-USER': '1',
        },
      }
    }
    return {}
  },

  checkAbResponse(response: any, hint: CheckResponseHint | null = null): void {
    try {
      if (!hint) {
        const authData: AuthData = response.data.data
        hint =
          authData.auth_type === 'login'
            ? CheckResponseHint.login
            : CheckResponseHint.register
      }
      // Check if there is ab experiment data in the response.
      // This is for the case the experiment has started on the server.
      ab.checkResponse(response.data, hint)
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  /*
   * Login the user via credentials: email and password pair.
   *
   * Note: we don't use this.prepareNewUser() method here because we don't
   * need to add A/B data, first_url and cookies for regular login.
   *
   * The `system` parameter indicates the system user we use during the build
   * process.
   * When set to true, it will add a special X-SF-SYSTEM-USER header to notify
   * the backend about it (we use it to set lower performance trcking sample rate).
   */
  async login(user: User, system: boolean = false): Promise<AuthData> {
    this.system = system
    const response = await request.post(
      `auth/token`,
      {
        email: user.email,
        password: user.password,
      },
      this.headers(),
    )
    this.checkAbResponse(response, CheckResponseHint.login)
    return response.data.data
  },

  async logout(): Promise<void> {
    await request.post(`auth/logout`)
    await localLogout()
  },

  async forgotPassword(user: User): Promise<void> {
    await request.post(`auth/password/reset`, {
      email: user.email,
    })
    try {
      Mixpanel.forgotPassword()
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  async resetPassword(data: ResetData): Promise<AuthUser> {
    const response = await request.post(`auth/password/reset/confirm`, data)
    try {
      Mixpanel.resetPassword()
    } catch (error) {
      Sentry.captureException(error)
    }
    const resp: AuthData = response.data.data
    auth.saveAuth(resp.token, resp.user)

    try {
      // Check if there is ab experiment data in the response.
      // This is for the case the experiment has started on the server.
      ab.checkResponse(response.data, CheckResponseHint.passwordReset)
    } catch (error) {
      Sentry.captureException(error)
    }
    return resp.user
  },

  async sendSupportRequest(subject: string, body: string): Promise<void> {
    await request.post(`users/support`, {
      subject: subject,
      body: body,
    })
  },

  async getTrustpilotId(): Promise<string> {
    const response = await request.get('/home/trustpilot_review')
    return response.data.id
  },

  async setTrustpilotSubmitted(): Promise<void> {
    await request.post('/home/trustpilot_review')
  },

  async getRecommendations(): Promise<Recommendation[]> {
    const response = await request.get(`recommendations/`)
    return response.data.data
  },

  async createToken(token: string): Promise<void> {
    await request.post(`push_registration_tokens/`, { token })
  },

  async deleteToken(token: string): Promise<void> {
    await request.delete(`push_registration_tokens/${token}`)
  },

  async getNotificationSettings(): Promise<NotificationSettings> {
    const response = await request.get(`notification_settings/`)
    return response.data
  },

  async putNotificationSettings(
    newSettings: NotificationSettings,
  ): Promise<NotificationSettings> {
    const response = await request.put(`notification_settings/`, newSettings)
    return response.data
  },

  async getAccountStatus(): Promise<AccountStatus> {
    const response = await request.get(`users/me`)
    return response.data
  },

  async getAccountStatusByDeleteToken(
    deleteToken: string,
  ): Promise<AccountStatus> {
    // Use URLSearchParams to create query parameters
    const encodedToken = encodeURIComponent(deleteToken)

    const response = await request.get(
      `users/me/token?delete_token=${encodedToken}`,
    )
    return response.data
  },

  async requestAccountDeletion(): Promise<void> {
    await request.post(`users/delete`)
    Mixpanel.trackAccountDeleteRequest()
  },

  async confirmAccountDeletion(deleteToken: string): Promise<void> {
    await request.post(`users/delete/confirm`, {
      delete_token: deleteToken,
    })
    // We log the user out after the account deletion confirmation,
    // need to wait for the mixpanel request to complete before we
    // do that.
    await Mixpanel.trackAccountDeleteConfim()
  },

  /**
   * Sets user_billing.change_plan_at_period_end to True on the backend.
   * Only available for Google billing subscriptions.
   */
  async markPlanChange(): Promise<void> {
    return await request.post(`billing/plan-change`)
  },

  /**
   * Sends the app store receipt validation request to our backend, switching
   * the URL depending on which platform is being used.
   *
   * The request body differs based on the plugin version:
   *   v11: AppStorePluginProduct (actually IapStore.IStoreProduct)
   *   v13: CdvPurchase.Validator.Request.Body
   *
   * We add the plugin major version number to the request body so that the
   * backend knows how to format the response data.
   */
  async postReceipt(
    platform: 'ios' | 'android',
    requestBody: AppStorePluginProduct | CdvPurchase.Validator.Request.Body,
    pluginMajorVersion: number,
  ): Promise<{
    data: { ok: boolean; data?: any; error?: { message: string } }
  }> {
    const url =
      platform === 'ios' ? 'billing/apple/receipt' : 'billing/google/receipt'
    const amendedRequestBody: any = requestBody
    amendedRequestBody.pluginMajorVersion = pluginMajorVersion
    return await request.post(url, amendedRequestBody)
  },

  /**
   * Set the user's ga4_client_id on the backend.
   */
  async setGa4ClientId(ga4ClientId: string): Promise<void> {
    return await request.post(`users/ga4_client_id`, {
      ga4_client_id: ga4ClientId,
    })
  },

  /**
   * Set the user's idfa on the backend.
   */
  async setAdjustData(newAdjustData: {
    adid?: string
    idfa?: string
  }): Promise<void> {
    if (!newAdjustData.adid && !newAdjustData.idfa) {
      return
    }
    return await request.post(`users/set_adjust_data`, newAdjustData)
  },

  /**
   * Set the user's adid on the backend.
   */
  async setAdid(adid: string): Promise<void> {
    return await request.post(`users/adid`, {
      adid: adid,
    })
  },

  /**
   * Get an excerpt to show on the Discover page.
   */
  async getExcerpt(): Promise<ExcerptData> {
    const response = await request.get('excerpt/')
    return response.data.data
  },

  /**
   * Get a specific excerpt by ID.
   */
  async getExcerptById(id: string): Promise<ExcerptData> {
    const response = await request.get(`excerpt/${id}`)
    return response.data.data
  },

  /**
   * Track that a specific excerpt was clicked.
   */
  async trackExcerptClick(excerptData: ExcerptData): Promise<void> {
    await request.post('excerpt/track', { id: excerptData.id })
  },

  /**
   * Get all docs the user has interacted with.
   */
  async getUserDocs(): Promise<Doc[]> {
    const response = await request.get(`home/docs`)
    return response.data
  },

  /*
   * Add rank field to the list of docs using the recommendations response
   */
  addRecommendations(
    docs: BookListItem[],
    recommendations: Recommendation[],
  ): BookListItem[] {
    if (recommendations && recommendations.length !== 0) {
      // Attach the rank from `recommendations` to the books.
      const rankMap = Object.fromEntries(
        recommendations.map(({ book_id, rank }) => [book_id, rank]),
      )
      // We use this number as the "worst rank" so that the sorting
      // function doesn't have to deal with null/undefined
      const lastRank = 1000
      docs.forEach((book) => {
        book.rank = rankMap[book.id] ?? lastRank
      })
    }
    return docs
  },

  /**
   * Get all books the user has interacted with and join them with recommendations data
   */
  async getUserBooksWithRecommendations(): Promise<ApiResultDocs> {
    const [docsResponse, recommendations] = await Promise.all([
      this.getUserDocs(),
      this.getRecommendations(),
    ])

    const books = docsResponse.filter((doc) => doc.doc_type === 'book')
    return { data: this.addRecommendations(books, recommendations) }
  },

  /**
   * Adds the given `doc` to the "Want to Read" custom list.
   */
  async addToWantToRead(doc: DocListItem): Promise<DocListItem> {
    const response = await request.post(
      `custom_lists/manage_doc_lists/${doc.id}`,
      {
        'want-to-read': true,
      },
    )
    return response.data.data
  },

  /**
   * Removes the given `doc` from the "Want to Read" custom list.
   */
  async removeFromWantToRead(doc: DocListItem): Promise<DocListItem> {
    const response = await request.post(
      `custom_lists/manage_doc_lists/${doc.id}`,
      {
        'want-to-read': false,
      },
    )
    return response.data.data
  },

  /**
   * Updates the given `doc`'s Custom Lists.
   *
   * @param doc the doc to be updated
   * @param lists an object with the list of changes,
   *   where the key is the list's URL slug and the value
   *   is `true` if the doc is being added to that list,
   *   or `false` if it's being removed from that list.
   */
  async updateCustomLists(
    doc: DocListItem,
    lists: Record<string, boolean>,
  ): Promise<DocListItem> {
    const response = await request.post(
      `custom_lists/manage_doc_lists/${doc.id}`,
      lists,
    )
    return response.data.data
  },

  /**
   * Get the current user's Custom Lists.
   */
  async getCustomLists(): Promise<CustomList[]> {
    const response = await request.get('custom_lists/')
    return response.data.data
  },

  /**
   * Get the custom list from the given `urlSlug`.
   */
  async getCustomList(urlSlug: string): Promise<CustomList> {
    const response = await request.get(`custom_lists/${urlSlug}`)
    return response.data.data
  },

  /**
   * Get the custom list from the given `urlSlug` and separate
   * its docs from the rest of the data to match `ApiResultListWithDocs`.
   */
  async getCustomListDocs(urlSlug: string): Promise<ApiResultListWithDocs> {
    const response = await this.getCustomList(urlSlug)
    const { docs, ...list } = response

    return {
      data: docs,
      list,
    }
  },

  /**
   * Create a new Custom List with the given `title`.
   */
  async createCustomList(title: string): Promise<CustomList> {
    const response = await request.post('custom_lists/create', { title })
    return response.data.data
  },

  /**
   * Rename the given `list` with the given `title`.
   */
  async renameCustomList(
    list: CustomListListItem,
    title: string,
  ): Promise<CustomList> {
    const response = await request.post(
      `custom_lists/modify/${list.url_slug}`,
      {
        title,
      },
    )
    return response.data.data
  },

  /**
   * Deletes the given `list`.
   */
  async deleteCustomList(list: CustomListListItem): Promise<void> {
    await request.delete(`custom_lists/${list.url_slug}`)
  },
}
